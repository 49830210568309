import React, { useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang'
import ApplicationModal from '../modals/ApplicationModal';

const PricingCard = ({card}) => {
    const {localizationsItems} = useLangContext();
    const [modalOpen, setModalOpen] = useState();

  return (
    <div className="col-lg-4 col-sm-12">
        <div className="pricing-boxed pricing-boxed-sm-bg">
            <div className="pricing-price">
                <h3><span className="price-unit price-unit-lg">{card.type}</span>{card.price}<span className="price-for">{card.subtitle}</span></h3>
            </div>
            <div className="pricing-feature"
                dangerouslySetInnerHTML={{__html: card.description}}
            >
            </div>
            <div className="pricing-cta pricing-cta-lg">
                <button className="btn btn-lg-s2 btn-capitalize width100" onClick={() => setModalOpen(true)}>{localizationsItems?.buy_now}</button>
            </div>
        </div>
        {modalOpen && <ApplicationModal show={modalOpen} setShow={setModalOpen}/>}
    </div>
  )
}

export default PricingCard