import MainPage from "../pages/main/MainPage";
import { ARTICLE_PAGE_ROUTE, MAIN_PAGE_ROUTE, PROJECTS_PAGE_ROUTE, ARTICLE_DETAIL_PAGE_ROUTE, ERROR_PAGE_ROUTE, EVENT_DETAIL_PAGE_ROUTE, EVENTS_PAGE_ROUTE} from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    }
]