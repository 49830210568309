import React from 'react'

const Featured = ({elem}) => {

  return (
	<div id="service" className="section section-x pt-0">
		<div className="nk-block nk-block-feature">
			<div className="container container-xl-full">
				<div className="row no-gutters text-center">
                    {elem.elements?.map((card, index) =>
                        <div className="col-xl-3 col-sm-6" key={index}>
                            <div className="feature-border">
                                <div className="feature feature-alt feature-s4">
                                    <div className="feature-icon">
                                        <img src={'https://solution-0001.panama.kz' + card.image} alt="" />
                                    </div>
                                    <div className="feature-content feature-content-s5">
                                        <h3>{card.title}</h3>
                                        <p dangerouslySetInnerHTML={{__html: card.description}}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
				</div>
			</div>
		</div>
	</div>
  )
}

export default Featured