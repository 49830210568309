import i18next from "i18next";

export default class Utils {
    static removeLastDirectoryPartOf(url) {
        let arr = url.split('/');
        arr.pop();
        return( arr.join('/') );
    }

    static getScrollBarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    static getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    static objectToSearchParams = (obj) => {
        return Object.entries(obj).map(([key, val]) => {
            if (val) {
                if (typeof val === 'object') {
                    return val.length ? val.map(val2 => `${key}[]=${val2}`).join('&') : null
                }
                else {
                    return `${key}=${val}`
                }
            } else {
                return null
            }
        }).filter(item => item)
            .join('&');
    }

    static transformCoordinates = (coordinates) => {
        return {
            latitude: coordinates && coordinates[0],
            longitude: coordinates && coordinates[1]
        }
    }

    static transformFiles = (files) => {
        return files.filter(item => !['error', 'uploading', 'removed'].includes[item.status]).map(item => {
            return {
                url: item.url ? item.url : item.response.content.value,
                type: item.type,
                name: item.name
            }
        })
    }

    static transformFilesFromServer = files => {
        return files.map((item, index) => {
            return {
                uid: -index,
                url: item.url,
                status: "done",
                type: item.type || 'image/jpg',
                name: item.name
            }
        })
    }

    static isEmail = email => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return !(!email || regex.test(email) === false);
    }

    static generateDate = (data) => {
        const month_ru = ["Январь", "Февраль", "Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","ноябрь","Декабрь"]
        const month_en =["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
        const month_kk = ["Қаңтар", "Ақпан", "Наурыз","Сәуір","Мамыр","Маусым","Шілде","Тамыз","Қыркүйек","Қазан","Қараша","Желтоксан"]

        const date = new Date(data);
        const count = date.getMonth()
        let month = month_en[count];
        if(i18next.language === 'ru'){
            month = month_ru[count];
        }else if(i18next.language === 'kk'){
            month = month_kk[count];
        }
        let dd = month + ' ' + date.getUTCDate() + ', <br/>' + date.getUTCFullYear()
        return dd
    }

    static  generateDate2 = (data, lang) => {
        const month_ru = ["Янв", "Фев", "Март","Апр","Май","Июнь","Июль","Авг","Сент","Окт","Нояб","Дек"];
        const month_kk = ["Қаңтар", "Ақпан", "Наурыз","Сәуір","Мамыр","Маусым","Шілде","Тамыз","Қыркүйек","Қазан","Қараша","Желтоксан"];
        const month_en = ["Jan", "Feb", "Mar","Apr","May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    
        const date = new Date(data);
        const count = date.getMonth()
        let month = lang === "ru" ? month_ru[count] : lang === "kk" ? month_kk[count] : lang === "en" ? month_en[count] :  month_ru[count];
    
        let dd = {
          day: date.getUTCDate(),
          mon: month
        }
        return dd
    }

    static youtube_parser = (image) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = image.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
      }
  
    static checkurl = (url) => {
        return url.replace("http://","").replace("https://","").replace("www.","").replace("youtu.be/","youtube.com?v=").slice(0,14)==="youtube.com?v=" || 
        url.replace("http://","").replace("https://","").replace("www.","").replace("youtu.be/","youtube.com?v=").slice(0,14)==="youtube.com/wa";
    }
}
