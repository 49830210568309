import React from 'react'

const TextComponent = ({elem}) => {
  return (
	<div className="section section-x section-intro">
		<div className="container container-lg-custom">
			<div className="row justify-content-center text-center">
				<div className="col-xl-8 col-lg-10">
					<div className="intro-inner">
						<h4 className="title-intro-sm t-u">{elem.subtitle}</h4>
						<h2 className="title-intro">{elem.title}</h2>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default TextComponent