import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import ArticleCard from '../simple/ArticleCard'
import { useLangContext } from '../../i18n/ProvideLang'

const NewsComponent = ({elem}) => {
    const {localizationsItems} = useLangContext();

    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(ArticleServices.index)

  return (
	<div className="section section-x" id="blog">
		<div className="container container-lg-custom">
			<div className="section-head section-head-s2 section-sm">
				<h2>{elem.title}</h2>
			</div>
			<div className="row gutter-vr-30px justify-content-sm-center">
                {items?.items?.slice(0,3).map((card, index) =>
                    <ArticleCard card={card} key={index}/>
                )}
			</div>
			<div className="post-btn text-center pdt-60">
				<a href="" className="btn btn-lg btn-lg-s2 round-sm btn-capitalize">{localizationsItems?.view_more}</a>
			</div>
		</div>
	</div>
  )
}

export default NewsComponent