import React, { useEffect, useState } from 'react'
import FeatureCard from '../simple/FeatureCard';

const AboutUs = ({elem}) => {
    const [elements, setElements] = useState();
    const [images, setImages] = useState();

    useEffect(() => {
        if(elem){
            setElements(elem.elements?.find(it => it.type === "text")?.elements);
            setImages(elem.elements?.find(it => it.type === "images")?.elements);
        }
    }, [elem])

  return (
	<div id="about" className="section section-x pt-0">
		<div className="nk-block nk-block-about">
			<div className="container container-lg-custom">
				<div className="row justify-content-between align-items-center gutter-vr-30px">
					<div className="col-xl-5 col-lg-6">
                        {images &&
                            <div className="image-block image-block-creative shape-dot-a">
                                {images[0] && <img className="image-main" src={'https://solution-0001.panama.kz' + images[0]} alt=""/>}
                                {images[1] && <img className="image-ball" src={'https://solution-0001.panama.kz' + images[1]} alt=""/>}
                            </div>
                        }
					</div>
					<div className="col-xl-6 col-lg-6 order-lg-first">
						<div className="text-block">
							<div className="section-head">
								<h2>{elem.title}</h2>
								<p className="lead lead-md"
                                    dangerouslySetInnerHTML={{__html: elem.description}}
                                ></p>
							</div>
							<div className="row gutter-vr-30px">
                                {elements?.map((card, index) =>
                                    <FeatureCard card={card} key={index}/>
                                )}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default AboutUs