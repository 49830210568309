import http from "./http-common"
import {getData} from "../handlers";

export default class PartnersServices {
    static index = (queryString) => {
        return getData(http, `/app/partners${queryString ? `?${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/partners/${slug}${queryString ? `${queryString}` : ""}`);
    }

    static categories = (queryString) => {
        return getData(http, `/app/partners/categories${queryString ? `${queryString}` : ""}`);
    }
}