import React from 'react'
import TeamCard from '../simple/TeamCard'

const Team = ({elem}) => {
  return (
	<div className="section section-x team team-s2 pt-0">
		<div className="nk-block nk-block-team">
			<div className="container container-xl-full">
				<div className="row no-gutters">
                    {elem.elements?.map((card, index) =>
                        <TeamCard key={index} card={card}/>
                    )}
				</div>
			</div>
		</div>
	</div>
  )
}

export default Team