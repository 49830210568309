import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLangContext } from '../../i18n/ProvideLang';
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';
import img1 from "../../images/contact-bg.png"

const FormContact = ({elem}) => {
    const {localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [success, setSuccess] = useState();

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const [item1, setItem1] = useState();
    const [item2, setItem2] = useState();

    useEffect(() => {
        if(elem){
            setItem1(elem.items[0])
            setItem2(elem.items[1])
        }
    }, [elem])

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {...data}
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setSuccess(true)
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
            setSuccess(false)
        }
        setLoading(false)
    }
    
  return (
	<div className="section section-x bg-form contact-bg-map has-bg-image" id="contacts">
		<div className="container container-lg-custom">
			<div className="row gutter-vr-40px justify-content-between align-items-center">
				<div className="col-lg-7 col-md-6 order-last pl-xl-5">
					<div className="row gutter-vr-30px">
						{item1 && <div className="col-sm-6 col-md-12 col-lg-6">
							<div className="contact-text contact-text-s3 bg-light box-pad box-pad-md">
								<div className="text-box"
                                dangerouslySetInnerHTML={{__html: item1.description}}>
								</div>
							</div>
						</div>}
						{item2 && <div className="col-sm-6 col-md-12 col-lg-6 order-lg-last order-first">
							<div className="contact-text contact-text-s3 bg-light box-pad mt-top box-pad-md">
								<div className="text-box"
                                dangerouslySetInnerHTML={{__html: item2.description}}>
								</div>
							</div>
						</div>}
					</div>
				</div>
				<div className="col-lg-5 col-md-6">
					<div className="section-head section-head-s2 section-sm">
						<h2>{elem.title}</h2>
					</div>
					<form className="genox-form genox-form-s2" onSubmit={handleSubmit(CustomSubmit)}>
						{visible && 
                                <div className={success ? "form-results green" : "form-results red"}>
                                    {success ? localizationsItems?.application_success : localizationsItems?.application_fail}
                                </div>
                        }
						<div className="row">
                            {elem.elements?.map((card, index) =>
                                <div className="form-field col-12" key={index}>
                                    <input name={card.name} type="text" {...register(card.name)} placeholder={card.placeholder} className="input bdr-b bdr-b-light required pt-0"/>
                                </div>
                            )}
							<div className="form-btn col-12">
								<button type="submit" className="btn btn-lg-s2 round-sm btn-capitalize">{localizationsItems?.submit}</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div className="bg-image overlay-fall bg-image-loaded" style={{backgroundImage: `url(${img1})`}}>
		</div>
	</div>
  )
}

export default FormContact