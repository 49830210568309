import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const MainBanner = ({elem}) => {
    const [banner, setBanner] = useState();
    const {localizationsItems} = useLangContext();

    useEffect(() => {
        if(elem){
            setBanner(elem.elements?.find(it => it.type === "banner_image")?.image)
        }
    }, [elem])

  return (
    <div className="banner bg-banner shadow-light">
        <div className="banner-block banner-alaska has-bg-image">
            <div className="container container-lg-custom">
                <div className="row ">
                    <div className="col-md-10 col-lg-7 offset-xl-0">
                        <div className="banner-content">
                            <h1 className="banner-heading banner-heading-s2">{elem.title}</h1>
                            <p className="lead lead-md lead-regular"
                                dangerouslySetInnerHTML={{__html: elem.description}}
                            ></p>
                            <div className="banner-btn" onClick={() => console.log('hello')}>
                                <a href="#contacts" className="btn btn-lg-s2 round-sm btn-initial btn-xbold menu-link">{localizationsItems?.banner_button}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-image overlay-fall bg-image-loaded" style={{backgroundImage: `url(https://solution-0001.panama.kz${banner})`}}></div>
        </div>
    </div>
  )
}

export default MainBanner