import React from 'react'
import PricingCard from '../simple/PricingCard'

const Pricing = ({elem}) => {
  return (
	<div className="section section-x tc-grey pt-0" id="pricing">
		<div className="container container-lg-custom">
			<div className="section-head section-head-s2 section-sm">
				<h2>{elem.title}</h2>
			</div>
			<div className="row justify-content-center gutter-vr-30px text-center">
                {elem.elements?.map((card, index) =>
                    <PricingCard card={card} key={index}/>
                )}
			</div>
		</div>
	</div>
  )
}

export default Pricing