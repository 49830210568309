import React from 'react'

const FeatureCard = ({card}) => {
  return (
    <div className="col-sm-6">
        <div className="feature-alt feature-alt-s3">
            <div className="feature-icon feature-icon-s4">
                <img src={'https://solution-0001.panama.kz' + card.image} alt="" />
            </div>
            <div className="feature-content feature-content-s5">
                <h3>{card.title}</h3>
                <p dangerouslySetInnerHTML={{__html: card.description}}></p>
            </div>
        </div>
    </div>
  )
}

export default FeatureCard