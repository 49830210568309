import React from 'react'

const ArticleCard = ({card}) => {
  return (
    <div className="col-md-12 col-lg-4">
        <div className="post post-alt d-md-flex d-lg-block align-items-md-center">
            <div className="post-thumb post-thumb-inline">
                <a href=""><img src={'https://solution-0001.panama.kz' + card.image} alt="post"/></a>
            </div>
            <div className="post-content post-content-s2">
                <p className="post-tag">{card.date}</p>
                <h4 className="post-title"><a href="">{card.title}</a></h4>
                <div className="content"
                dangerouslySetInnerHTML={{__html: card.description}}>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ArticleCard