import React, {createContext, useContext} from 'react';
import useLang from "../hooks/useLang.hook"

const langContext = createContext(null);

const ProvideLang = ({children}) => {
    const data = useLang()

    return (
        <langContext.Provider value={data}>
            {children}
        </langContext.Provider>
    );
};

export default ProvideLang;

export function useLangContext() {
    return useContext(langContext);
}