import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReviewCard from '../simple/ReviewCard';

const Reviews = ({elem}) => {
    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: false,
        speed: 1200,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };
  return (
	<div className="section section-x pt-0" id="testimonial">
		<div className="container container-lg-custom">
			<div className="section-head section-head-s2 section-sm">
				<h2>{elem.title}</h2>
			</div>
            {elem?.elements &&
                <div className="tes tes-s3 tes-s4">
                    <Slider {...settings} className="row has-carousel">
                        {elem.elements.map((card, index) =>
                            <ReviewCard card={card} key={index}/>
                        )}
                    </Slider>
                </div>
            }
		</div>
	</div>
  )
}

export default Reviews