import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ProjectServices from '../../services/general/projectServices'
import WorkCard from '../simple/WorkCard'

const Portfolio = ({elem}) => {
    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(ProjectServices.index)

  return (
	<div className="section section-x section-project pt-0" id="portfolio">
		<div className="nk-block nk-block-project">
			<div className="container container-lg-custom">
				<div className="section-head section-head-s2 section-sm">
					<h2>{elem.title}</h2>
				</div>
				<div className="project-wrapper">
					<div className="projects-grid" id="project1">
                        {items?.items?.map((card, index) => 
                            <WorkCard card={card} key={index}/>
                        )}
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Portfolio