import React from 'react'

const AboutVideo = ({elem}) => {
  return (
    <div className="section section-x pt-0">
        <div className="nk-block nk-block-xl nk-block-about">
            <div className="container container-lg-custom">
                <div className="row gutter-vr-50px">
                    <div className="col-xl-7 col-md-6">
                        <div className="video-image">
                            <img src={'https://solution-0001.panama.kz' + elem.video_image} alt=""/>
                            <a href={elem.video} className="video-btn video-popup"><i className="fas fa-play"></i></a>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                        <div className="text-block">
                            <div className="section-head section-head-s2 section-md text-lg-right mtm-20">
                                <h2>{elem.title}</h2>
                            </div>
                            <div className="quote-block bg-white ml-reverse">
                                <div className="quote-content">
                                    <div className="quote-icon">
                                        <i className="icon icon-lg ikon ikon-quote"></i>
                                    </div>
                                    <p className="lead lead-regular"
                                    dangerouslySetInnerHTML={{__html: elem.description}}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutVideo