import React from 'react'

const TeamCard = ({card}) => {
  return (
    <div className="col-xl-3 col-sm-6">
        <div className="team-single">
            <div className="team-image">
                <img src={'https://solution-0001.panama.kz' + card.image} alt=""/>
            </div>
            <div className="team-hover tc-light">
                <div className="team-content">
                    <h5 className="team-name tc-primary">{card.name}</h5>
                    <p className="team-desig">{card.position}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TeamCard