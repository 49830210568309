import React from 'react'

const WorkCard = ({card}) => {
  return (
    <div className="projects-grid-item">
        <a href="texas-work-single.html">
            <div className="project-item">
                <div className="project-image">
                    <img src={'https://solution-0001.panama.kz' + card.image} alt=""/>
                </div>
                <div className="project-over">
                    <div className="project-content">
                        <h4>{card.title}</h4>
                        <p>{card.subtitle}</p>
                    </div>	
                </div>
            </div>
        </a>
    </div>
  )
}

export default WorkCard