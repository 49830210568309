import React from 'react'
import PartnersServices from '../../services/general/partnersServices'
import useFetchItems from '../../hooks/useFetchItems'

const Partners = ({elem}) => {
    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(PartnersServices.index)

  return (
	<div className="section section-x pt-0">
		<div className="container container-lg-custom">
			<div className="row justify-content-center justify-content-lg-between gutter-vr-30px align-items-center">
                {items?.items?.map((card, index) => 
                    <div className="col-sm-4 col-md-2 col-5" key={index}>
                        <div className="logo-item">
                            <img src={'https://solution-0001.panama.kz' + card.image} alt=""/>
                        </div>
                    </div>
                )}
			</div>
		</div>
	</div>
  )
}

export default Partners