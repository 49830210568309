import React, { useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const Footer = ({item}) => {
    const [socials, setSocials] = useState();
    const [logo, setLogo] = useState();
    const {lang, localizationsItems} = useLangContext();
    
    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

    useEffect(() => {
        if(item){
            setSocials(item.find(it => it.type === "socials"));
            setLogo(item.find(it => it.type === "white-logo")?.value);
        }
    }, [item])

  return (
	<footer className="section footer section-footer-less">
		<div className="container container-lg-custom">
			<div className="row gutter-vr-10px gutter-50px">
				<div className="col-sm-12 col-lg-2">
					<div className="wgs">
						<div className="wgs-content">
							<div className="wgs-logo">
								<NavLink to={`/${lang}`}>
									<img src={'https://solution-0001.panama.kz' + logo} alt="logo"/>
								</NavLink>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-8 col-lg-6">
					<div className="wgs">
						<div className="wgs-content">
							<ul className="wgs-menu wgs-menu-s2">
                                {items?.map((card, index) =>
								    <li key={index}><a href={card.value} target={card.type} className="">{card.title}</a></li>
                                )}
							</ul>
							<p className="copyright-sm">&copy; Panama.kz</p>
						</div>
					</div>
				</div>
				<div className="col-sm-4 col-lg-4 order-lg-last order-last">
					<div className="wgs text-sm-right">
                        {socials?.value.find(el => el.value) &&
                            <div className="wgs-content wgs-content-s2">
                                <h4>{localizationsItems?.socials}</h4>
                                <ul className="social social-s2">
                                    {socials.value.map((social, index) =>
                                        social.value &&
                                            <li key={index}>
                                                <NavLink to={social.value} target="_blank">
                                                    <i className={
                                                        social.type === "facebook" ? "fab fa-facebook-f"
                                                        :
                                                        social.type === "twitter" ? "fab fa-twitter"
                                                        :
                                                        social.type === "pinterest" ? "fab fa-pinterest-p"
                                                        :
                                                        social.type === "instagram" ? "fab fa-instagram"
                                                        :
                                                        social.type === "linkedin" ? "fab fa-linkedin-in"
                                                        :
                                                        social.type === "vk" ? "fab fa-vk"
                                                        :
                                                        social.type === "whatsapp" ? "fab fa-whatsapp"
                                                        :
                                                        "fab fa-facebook-f"
                                                        }></i>
                                                </NavLink>
                                            </li>
                                    )}
                                </ul>
                            </div>
                        }
					</div>
				</div>
			</div>
		</div>
	</footer>
  )
}

export default Footer