import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';

const ApplicationModal = ({show, setShow}) => {
    const handleClose = () => setShow(false);
    const {localizationsItems, lang} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [success, setSuccess] = useState();

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {...data}
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setSuccess(true)
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
            setSuccess(false)
        }
        setLoading(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered  size='lg'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className="genox-form genox-form-s2" onSubmit={handleSubmit(CustomSubmit)}>
                <div className='text-center'>
					<h2>{localizationsItems?.modal_title}</h2>
                    <p>{localizationsItems?.modal_content}</p>
                </div>
                <div className="form-field col-12">
                    <input type="text" {...register('name')} placeholder={localizationsItems?.name} className="input bdr-b bdr-b-light required pt-0"/>
                </div>
                <div className="form-field col-12">
                    <input type="text" {...register('phone')} placeholder={localizationsItems?.phone} className="input bdr-b bdr-b-light required pt-0"/>
                </div>
                <div className="form-field col-12">
                    <input type="text" {...register('message')} placeholder={localizationsItems?.message} className="input bdr-b bdr-b-light required pt-0"/>
                </div>
                {visible && 
                    <div className={success ? "form-results green" : "form-results red"}>
                        {success ? localizationsItems?.application_success : localizationsItems?.application_fail}
                    </div>
                }
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <div className="form-btn">
                            <button type="submit" className="btn btn-lg-s2 round-sm btn-capitalize">{localizationsItems?.submit}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
}

export default ApplicationModal