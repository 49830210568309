import React from 'react'

const ReviewCard = ({card}) => {
  return (
    <div className="tes-block tes-block-transparent">
        <div className="tes-content tes-content-box bg-light">
            <blockquote className="lead lead-regular font-normal"
            dangerouslySetInnerHTML={{__html: card.description}}>
            </blockquote>
            <div className="tes-author d-flex align-items-center">
                <div className="author-icon">
                    <i className="icon icon-md ikon ikon-quote-o"></i>
                </div>
                <div className="author-con">
                    <h6 className="author-name t-u">{card.title}</h6>
                    <p className="author-desig font-italic">{card.subtitle}</p>
                </div>
            </div>
        </div>
        <div className="author-image author-image-lg round-full">
            <img className="round-full" src={'https://solution-0001.panama.kz' + card.image} alt="Author Image"/>
        </div>
    </div>
  )
}

export default ReviewCard