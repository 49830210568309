import React, { useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';

const Header = ({item}) => {
    const [sticky, setStiky] = useState();
    const [searchVisible, setSearchViisble] = useState(false);
    const [logo, setLogo] = useState();
    const [menuOpen, setMenuOpen] = useState();

    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "logo")?.value);
        }
    }, [item])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 110){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    const searchOpen = () => {
        setSearchViisble(true)
    }

  return (
	<header className={sticky ? "is-sticky is-shrink bg-header has-fixed" : "is-sticky is-shrink bg-header"} id="header">
		<div className="header-box shadow-light">
			<div className={searchVisible ? "header-main search-show" : "header-main"}>
				<div className="container container-lg-custom">
					<div className="header-wrap">
						<div className="header-logo logo">
							<a href="./" className="logo-link">
								<img className="logo-white" src={'https://solution-0001.panama.kz' + logo} alt="logo"/>
							</a>
						</div>
						<div className="header-nav-toggle">
							<div className={menuOpen ? "navbar-toggle active" : "navbar-toggle"} data-menu-toggle="header-menu" onClick={() => setMenuOpen(!menuOpen)}>
								<div className="toggle-line">
									<span></span>
								</div>
							</div>
						</div>
						<div className={menuOpen ? "header-navbar menu-mobile menu-shown" : "header-navbar menu-mobile"}>
							<nav className="header-menu" id="header-menu">
								<ul className="menu menu-s2">
                                    {items?.map((it, index) =>
                                    	<li className="menu-item" key={index}><a className="menu-link nav-link" href={it.value}>{it.title}</a></li>
                                    )}
								</ul>
							</nav>
                            <div className="header-navbar-overlay" onClick={() => setMenuOpen(false)}></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
  )
}

export default Header