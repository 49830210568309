import {useEffect, useState} from 'react';
import i18n from "i18next";
import AppServices from '../services/general/appServices';

const UseLang = () => {
    const [lang, setLang] = useState();
    const [localizationsItems, setLocalizationsItems] = useState();
    const [languages, setLanguages] = useState();

    const localizations = async () => {
        const res = await AppServices.localizations();

        if(res.statusCode === 200){
            let temp;
            if(res.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            setLang(temp)
            setLocalizationsItems(res.content.find(it => it.slug === temp).description)
            setLanguages(res.content)
        }else{
            console.log(res)
        }
    }

    useEffect(() => {
        localizations();
    }, [lang])

    const changeLanguage = (newLang) => {
        i18n.changeLanguage(newLang)
            .then(() => {
                setLang(newLang);
            });
    }

    return {
        lang,
        changeLanguage,
        localizationsItems,
        setLocalizationsItems,
        languages
    };
};

export default UseLang;