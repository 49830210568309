import React, { useEffect } from 'react'
import MainBanner from '../../components/complex/MainBanner'
import Reviews from '../../components/complex/Reviews'
import Team from '../../components/complex/Team'
import Pricing from '../../components/complex/Pricing'
import Partners from '../../components/complex/Partners'
import FormContact from '../../components/complex/FormContact'
import NewsComponent from '../../components/complex/NewsComponent'
import AboutUs from '../../components/complex/AboutUs'
import TextComponent from '../../components/complex/TextComponent'
import AboutVideo from '../../components/complex/AboutVideo'
import Featured from '../../components/complex/Featured'
import Portfolio from '../../components/complex/Portfolio'
import { useNavigate, useParams } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang';
import AppServices from '../../services/general/appServices'
import useFetchItems from '../../hooks/useFetchItems';

const MainPage = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();
    const {lang: lang2} = useParams();
  
    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')

  return (
    <div>
        {items &&
            items.elements?.map((elem, index) =>
                <div key={index}>
                    {
                        elem.type === "opening-3" ?
                            <MainBanner elem={elem}/>
                        :
                        elem.type === "text-component" ?
                            <TextComponent elem={elem}/>
                        :
                        elem.type === "about" ?
                            <AboutUs elem={elem}/>
                        :
                        elem.type === "steps" ?
                            <Featured elem={elem}/>
                        :
                        elem.type === "projects" ?
                            <Portfolio elem={elem}/>
                        :
                        elem.type === "reviews" ?
                            <Reviews elem={elem}/>
                        :
                        elem.type === "packages" ?
                            <Pricing elem={elem}/>
                        :
                        elem.type === "partners" ?
                            <Partners elem={elem}/>
                        :
                        elem.type === "form" ?
                            <FormContact elem={elem}/>
                        :
                        elem.type === "workers" ?
                            <Team elem={elem}/>
                        :
                        elem.type === "articles" ?
                            <NewsComponent elem={elem}/>
                        :
                        elem.type === "video-component" ?
                            <AboutVideo elem={elem}/>
                        :
                        <></>
                    }
                </div>
            )
        }
    </div>
  )
}

export default MainPage